import Api from "../Helper/Constant";
import ApiFetch from "../Helper/FetchApi";

export interface SettingModel {
    _id: string;
    companyTitle: string;
    logo: string;
}

/**@GETTING_SETTING_DETAILS */
export const getSettingInfo = async () => {
    if (localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/setting`)) {
        return await JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/setting`) || '{}');
    } else {
        const result: any = await getSettings();
        if (result && result.data) {
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}/setting`, JSON.stringify(result.data));
            return result.data;
        }
    }
}
export const getComplianceData = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(isOutSide ? Api.getCompliancUser : Api.getCompliance);
}

/**@GETTING_SETTING_DETAILS */
export const getSettingInfoRefresh = async (isOutSide?: boolean) => {

    return ApiFetch.fetchGet(isOutSide ? Api.getSettingsOutside : Api.getSettings);
}


export const isAuthenticated = () => {
    return localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`) ? true : false;
};


/**@GET_SETTING */
export const getSettings = async () => {
    return ApiFetch.fetchGet(`${Api.getSettings}`);
}

/**@UPDATE_SETTING */
export const updateSettings = async (id: string, body: any) => {
    return ApiFetch.fetchPut(`${Api.updateSettings}/${id}`, JSON.stringify(body));
}

/**@POST_SETTING */
export const savetSettings = async (body: any) => {
    return ApiFetch.fetchPost(`${Api.saveSettings}`, JSON.stringify(body));
}

/**@GET_ClientDetails */
export const getClientDetails = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(isOutSide ? Api.getclientDetailsOutside : Api.getclientDetails);
    
}

/**@POST_ClientDetails_DOMAINAME */
export const getClientDomainupdate = async (id: string, body: any) => {
    return ApiFetch.fetchPut(`${Api.saveDomainStatus}/${id}`, JSON.stringify(body));
}

/**@UPDATE_THEME_COLOSRS */
export const updateThemeColorsFun = async (id: string, body: any) => {
    if(!id){
        return ApiFetch.fetchPost(`${Api.saveThemeColors}`, JSON.stringify(body));
    }
    return ApiFetch.fetchPut(`${Api.updateThemeColors}/${id}`, JSON.stringify(body));
}


/**====>>>>>>>>>>>>>> @NDA =====>>>>>>>>>>>>>>>>>>>>>>> */

/**@NDA_SAVE_UPDATE */
export const updateOrsaveNDA = async (id:string, body: any) => {
    if (id) {
        return ApiFetch.fetchPut(`${Api.updateNda}/${id}`, JSON.stringify(body));
    } else {
        return ApiFetch.fetchPost(`${Api.saveNda}`, JSON.stringify(body));
    }
   
}

/**@GET_ClientDetails */
export const getNdadata = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(isOutSide ? Api.getoutsideNda : Api.getNda);
    
}









