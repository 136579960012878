import React, { useEffect, useState } from 'react'
import './loading.css'

interface Props {
    width?: number;
    height?: number;
    color?: 'success' | 'error' | 'primary' | 'warn' | 'light' | 'default'
}

const Loading = () => {


    return (
        <div className="sk-flow">
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
      </div>
    )
}

export default Loading;