import React, { useEffect, useState } from 'react';
import './CheckMark.css';
import CheckMark2 from '../CheckMarkNoAni';

interface Props {
    width: number;
    height: number;
    color: 'success' | 'error' | 'primary' | 'warn' | 'light' | 'default';
    refresh?: boolean;
    trigger?: boolean;
    index?: number;
}

const CheckMark = React.memo(({ width, height, color, refresh, trigger, index }: Props) => {
    const [isRefreshed, setIsRefreshed] = useState(false);
    
    useEffect(() => {
        console.log('trigger: ', trigger);
        console.log('refresh: ', refresh, index);
        console.log('color: ', color);
        setIsRefreshed(true);
        setTimeout(() => {
            setIsRefreshed(false);
        }, 50);
    }, [trigger]);
    
    if (refresh) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={`svg-${color} ${isRefreshed ? 'svg-box-refeshed' : 'svg-box'}`} style={{ height, width }} viewBox="0 0 24 24">
                <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} stroke="none">
                    <circle className={`${color}-circle-outline circle-outline`} cx="12" cy="12" r="11.5" />
                    <circle className={`${color}-circle-fill circle-fill`} cx="12" cy="12" r="11.5" />
                    <polyline className={`${color}-tick tick`} points="17,8.5 9.5,15.5 7,13" />
                </g>
            </svg>
        );
    } else {
        return <CheckMark2 width={width} height={height} color={color} />;
    }
});

export default CheckMark;