 const ColorMapping = (letter:string) => {
    const colors:any = {
      A: "#FF5733", B: "#33FF57", C: "#3357FF", D: "#FF33A1", E: "#FF8C33",
      F: "#33FFF0", G: "#8C33FF", H: "#FF3333", I: "#33FF7F", J: "#333FFF",
      K: "#FF333F", L: "#FF8C33", M: "#8CFF33", N: "#33FF57", O: "#57FF33",
      P: "#FF3357", Q: "#3357FF", R: "#5733FF", S: "#33A1FF", T: "#33FF8C",
      U: "#FF5733", V: "#33FF8C", W: "#33FF57", X: "#57FF33", Y: "#FF33A1",
      Z: "#8C33FF"
    };
    return colors[letter.trim().toUpperCase()] || "#333333"; // Default to a gray color if letter is not found
  };

  export default ColorMapping;