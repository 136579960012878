import Api from "./../Helper/Constant";
import ApiFetch from "./../Helper/FetchApi";

/** @POST_EMAIL_FORGOT_PASSWORD */
export const postForgotPassord = async (data:any) => {
    return ApiFetch.fetchPost(`${Api.userForgotPassword}`, JSON.stringify(data));
}

/** @POST_OTP_FORGOT_PASSWORD */
export const userOtpValidation = async (data:any) => {
    return ApiFetch.fetchPost(`${Api.userOtpValidation}`, JSON.stringify(data));
}
/** @POST_CONAGE_FORGOT_PASSWORD */
export const userPasswordChange = async (data:any) => {
    return ApiFetch.fetchPost(`${Api.userPasswordChange}`, JSON.stringify(data));
}
