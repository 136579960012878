import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from 'redux-persist-transform-encrypt';

/** @Import_All_reducers */
import AppReducer from "./Reducers/AppReducer";
// import AuthReducer from "./Reducers/Authentication";

// Create the encryption transform
const encryptor: any = encryptTransform({
    secretKey: `@${process.env.REACT_APP_STORAGE_KEY}~sectrans!2024`, // Use a secure and unique secret key
    onError: (error: any) => {
        // Handle encryption errors
        console.error('Encryption error:', error);
    },
});

// Configuration for redux-persist
const persistConfig = {
    key: process.env.REACT_APP_STORAGE_KEY || 'root', // Root key for persisted state
    storage, // Storage method
    version: Number(process.env.REACT_APP_VERSION), // Version number for persisted state
    whitelist: ['AppReducer']
    // transforms: [encryptor], // Apply the encryption transform
}

// Combine all reducers into a root reducer
const reducerCombinationAsRoot: any = combineReducers({
    // Combine all reducers here like so: AppReducer,
    AppReducer,
    // AuthReducer,
});

// Apply persistReducer to the root reducer
const persistedState = persistReducer(persistConfig, reducerCombinationAsRoot);
export default persistedState;