import React, { useState, useEffect } from 'react';
import { confirmationManager } from './ConfirmationManager';
import './Confirmation.css';

const ConfirmationDialog: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [heading, setHeading] = useState('');
    const [onConfirm, setOnConfirm] = useState<() => void | null>();
    const [onCancel, setOnCancel] = useState<() => void | null>();
    const [show, setShow] = useState(true);

    useEffect(() => {
        confirmationManager.setCallbacks({
            openDialog: ({ heading, message, onConfirm, onCancel, show }) => {
                if (typeof show !== 'undefined') {
                    setShow(show);
                }
                
                setMessage(message);
                setHeading(heading || ''); // Default to empty string if heading is not provided
                setOnConfirm(() => () => {
                    onConfirm();
                    closeDialog();
                });
                setOnCancel(() => () => {
                    if (onCancel) onCancel();
                    closeDialog();
                });
                setIsOpen(true);
            },
            closeDialog: () => {
                setIsOpen(false);
            }
        });
    }, []);

    const closeDialog = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="confirmation-dialog">
            <div className="confirmation-dialog-content">
                <div className="d-flex flex-row align-items-center">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <div>
                        <h4 style={{fontSize: 18, fontWeight: '900'}}>
                            {heading || 'Are you sure?'}
                        </h4>
                        <p>{message}</p>
                    </div>
                </div>
                
                <div style={{borderTop: '1px solid #eee'}} className="text-end pt-2 pb-2">
                    {show && (
                        <button onClick={onCancel} className="text-danger btn btn-sm">Cancel</button>
                    )}
                    <button className="btn btn-sm btn-primary" onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;