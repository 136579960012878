import React, { useState, ReactNode } from 'react';
import ReactContext from './ReactContext';

interface Props {
  children: ReactNode;
}

const initialData:any={
    complianceList:[],
    securityList:[],
    reloadType:'',
}

const Provider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<any>(initialData);

  const reload = (v: string) => {
    setData((state:any)=>({...state, reloadType:v})); // Update state or perform any action with the value
  };

  return (
    <ReactContext.Provider value={{ data, setData, reload }}>{children}</ReactContext.Provider>
  );
};
export default Provider;