import React, { lazy, memo, Suspense } from 'react';
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import SideMenu from './../../Components/SideMenu';
import './TrustCenterEditorStyles.css';

const OverviewComlianceTruestCenter = lazy(() => import('./OverviewComlianceTruestCenter'));
const EditorDocuments = lazy(() => import('./EditorDocuments'));
const SecurityCard = lazy(() => import('./../../SecurityCard/SecurityCard'));

const TrustCenterEditor: React.FC = memo(() => {
    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'Trust Center Editor'} />
                <div className="router-container pt-3">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 offset-lg-1 offset-xl-1 offset-xxl-1">
                        <Suspense fallback={<div>Loading...</div>}>
                            <OverviewComlianceTruestCenter/>
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <EditorDocuments />
                        </Suspense>
                        <div className="mt-4"></div>
                        <Suspense fallback={<div>Loading...</div>}>
                            <SecurityCard isEditor={true} />
                        </Suspense>
                    </div>
                </div>
            </section>
        </main>
    )
})

export default TrustCenterEditor;