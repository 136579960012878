import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import SideMenu from './../../Components/SideMenu';
import React from 'react'

const AiQuestionnaireAutoComplete = () => {
    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title='Questionnaire Auto Complete' />
                <div className="router-container">
                    Coming soon...
                </div>
            </section></main>
    )
}

export default AiQuestionnaireAutoComplete;