import axios from 'axios';


const API_URL = process.env.REACT_APP_FLASK_SERVER_URL;


interface CheckEmailResponse {
    exists: boolean;
    company?: string;
    error?: string;
}

export const checkEmailExists = async (email: string): Promise<CheckEmailResponse> => {
    try {
        const response = await axios.get<CheckEmailResponse>(`${API_URL}/check_email`, { params: { email } });
        return response.data;
    } catch (error) {
        console.error("Error checking email:", error);
        throw error;
    }
};


// interface UpdateProvisionedResponse {
//     success?: boolean;
//     message?: string;
//     error?: string;
//     createInfra?: boolean;
// }

export const updateProvisionedStatus = async (email: string) => {
    try {
        console.log('Email being sent:', email);  // Add this line
        const response = await axios.put(`${API_URL}/update_provisioned`, { email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error checking email:", error);
        return (error as Error).message;
    }
};

interface SubdomainTestResponse {
    message?: string;
    subdomain: string;
    error?: string;
}

export const fetchS3Buckets = async (): Promise<SubdomainTestResponse> => {
    try {
        const response = await axios.get<SubdomainTestResponse>(`${API_URL}/api`);
        console.log("response.data suubdomain", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching S3 buckets:", error);
        throw error;
    }
};




export const addSubdomain = async (domainName: string) => {
    try {
        const response = await axios.post(`${API_URL}/add-cname`, { subdomain: domainName }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error checking email:", error);
        return (error as Error).message;
    }
};
interface domainResponse {
    status?: string;
    record: string;
    error?: string;
}

export const checkSubdomain = async (subdomain: string): Promise<domainResponse> => {
    try {
        const response = await axios.get<domainResponse>(`${API_URL}/check-cname`, { params: { subdomain } });
        return response.data;
    } catch (error) {
        console.error("Error checking email:", error);
        throw error;
    }
};