import ApiFetch from '../Helper/FetchApi';
// import { } from '../Models/SecurityModel';
import Api from '../Helper/Constant';
import { persistor } from './../Redux/Store';

/** @GER_AUTH_INFO */
export const getAuth = () => {
    const auth:any=localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`);
    return JSON.parse(auth || '{}');
}

/** @POST_USER_REGISTER */
export const postUsercreate = async (data:any) => {
    return ApiFetch.fetchPost(`${Api.useCreate}`, JSON.stringify(data));
}

export const logoutGlobal = () => {
   try {
    console.log('AA');
    localStorage.clear();
    persistor.flush().then(async() => {
        await persistor.purge();
        console.log('CCC');
        localStorage.setItem('appVersion', `${process.env.REACT_APP_VERSION}`);
        // window.location.href = '/login';
    });
    console.log('DD');
   } catch (error) {
     console.log(error, 'error');
    
   }
}



// /**@DLETE_SECURITY_QUESTION */
// export const deleteAllSecurity = async (id: string) => {
//     return ApiFetch.fetchDelete(`${Api.deleteAllSecurity}${id}`, []);
// }