// EnterpriseApps.tsx
import React, { Suspense, useEffect, lazy } from 'react';
import './TrustCenter.css';
const StickyNavbar = lazy(() => import('../StickyNavbar/StickyNavbar'));
const CollapsibleOverview = lazy(() => import('./Overview'));
const SecurityReview = lazy(() => import('./SecurityReview'));
const OverviewCompliance = lazy(() => import('./OverviewCompliance'));
const Documents = lazy(() => import('./Documents'));
const SecurityCard = lazy(() => import('./../SecurityCard/SecurityCard'));


const TrustCenter: React.FC = () => {
  useEffect(() => {
    return () => {
      console.log('Cleanup called');
    };
  }, []);

  return (
    <div className='containerr'>
      <Suspense fallback={<div></div>}>
        <StickyNavbar />
      </Suspense>
      <div className='fullPage'>
        <div className="overview-container">
          <Suspense fallback={<div></div>}>
            <CollapsibleOverview />
          </Suspense>
        </div>
        <div className="compliance-container">
          <Suspense fallback={<div></div>}>
            <SecurityReview />
          </Suspense>
        </div>
        <div className="document-container">
          <Suspense fallback={<div>Loading...</div>}>
            <OverviewCompliance />
          </Suspense>
        </div>
        <div className="document-container mb-4">
          <Suspense fallback={<div></div>}>
            <Documents />
          </Suspense>
        </div>
        <div className="securitycard-container mb-4">
          <Suspense fallback={<div></div>}>
            <SecurityCard />
          </Suspense>
        </div>
      </div>
    </div>
  );

};

export default TrustCenter;
