import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './AuthManager.css';
import useToken from '../../ApiServices/useToken';
import { toastManager } from '../../Components/ToastDialog/ToastManager';
import { getSettings, isAuthenticated } from '../../ApiServices/SettingsApi';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  Provision from './Provision/index';

function AuthManager() {    

        return (
            <div className="homepage-containerr">
                <img src={process.env.PUBLIC_URL + 'assets/imgs/watermark.png'} alt="watermark" className="watermark" />
                <div className="homepage-sectionn">
                    <img src={process.env.PUBLIC_URL + 'assets/imgs/logo.png'} alt="Targhee security" className="logo-img" />
                    <div className="provision">
                        <Provision  />
                    </div>
                </div>    
            </div>
        );
    }
    
    

export default AuthManager;