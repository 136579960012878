import Api from "./../Helper/Constant";
import ApiFetch from "./../Helper/FetchApi";


/**@GET_FUSER_PROILE */
export const userProfile = async (id:string) => {
    return ApiFetch.fetchGet(`${Api.userProfile}${id}`);
}

/**@UPDATE_FUSER_PROILE */
export const updateUserProfile = async (id: string, reqBody: any) => {
    return ApiFetch.fetchPut(`${Api.updateUserProfile}${id}`, JSON.stringify(reqBody));
}

