import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Provision.css';
import useToken from '../../../ApiServices/useToken';
import Loading from '../../../Components/LoadingAnimation/index';
import { getSettings, isAuthenticated } from '../../../ApiServices/SettingsApi';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastManager } from '../../../Components/ToastDialog/ToastManager';
import {  updateProvisionedStatus, fetchS3Buckets } from '../../../ApiServices/ValidationApis';
import CheckMark from '../../../Components/CheckMark';



const Provision: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [provisioned, setProvisioned] = useState(false); // New state to track provisioning success


  useEffect(() => {
    const provisionInfra = async () => {
      const responsee = await fetchS3Buckets();
      const email = await getTokenUserData();
      console.log("email from react", email);
      const response = await updateProvisionedStatus(email);
      console.log("response from react", response);

      if (response && response?.success) {
        console.log("Provisioned status updated successfully:", response);
        
        setTimeout(() => {
            setLoading(false)
            setProvisioned(true); // Set provisioned to true after the delay
            navigate('/trust-center-editor');
        }, 4000); // 2000 milliseconds (2 seconds) delay
      } else {
        
        setTimeout(() => {
            setLoading(false)
            console.log("Provisioned status updated unsuccessfully:", response);
          }, 4000); // 2000 milliseconds (2 seconds) delay
        console.error("Error updating provisioned status:", response.message);
         navigate('/login');
      }; // Set loading to false after the async call completes

    };

    provisionInfra();
  }, []);
  
  const getTokenUserData = async (): Promise<any> => {
    try {
        const tokenUserData: any = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`);
        const email = JSON.parse(tokenUserData).email
        return email;
    } catch (error) {
        console.error('Error retrieving token user data:', error);
        throw error;
    }
};

  return (
    <div className="provision-container">
      <p className='screen-title'>Provisioning Infrastructure For Trust Center</p>
      {loading ? (
        <div className="loadinggg">
          <Loading />
        </div>
      ) : (
        provisioned && <CheckMark height={40} width={40} color={'success'} />
      )}
    </div>
  );
}

export default Provision;