import React, { useState, useRef, ChangeEvent, KeyboardEvent, ClipboardEvent } from 'react';
interface Props{
    onChangeOtp:(e:any)=>void;
}
const OtpInput =({onChangeOtp}:Props) => {
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const inputs = useRef<(HTMLInputElement | null)[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        if (/^\d$/.test(value)) { // Only accept digits
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < 3) {
                inputs.current[index + 1]?.focus();
            }
            const filteredArray = newOtp.filter(value => value !== "");
            if(filteredArray.length==4){
                onChangeOtp(newOtp.join(''))
            }
        } else if (value === '') { // Allow backspace to clear the input
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            const filteredArray = newOtp.filter(value => value !== "");
            if(filteredArray.length==4){
                onChangeOtp(newOtp.join(''))
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
        const pasteData = e.clipboardData.getData('text');
        if (/^\d{4}$/.test(pasteData)) { // Only accept exactly 4 digits
            const newOtp = pasteData.split('');
            setOtp(newOtp);
            inputs.current[3]?.focus();
            onChangeOtp(newOtp.join(''))
        }
        e.preventDefault();
    };

    return (
        <div>
            <h4 className='text-white fs-3 mb-2'>OTP Verification</h4>
            <p className='mb-5' style={{color:'rgb(176 173 169)'}}>Please enter the OTP sent to your registered Email ID</p>
            <div onPaste={handlePaste} style={{display:'flex', justifyContent:'center', gap:'10px'}}>
            
                {otp.map((digit, index) => (
                    <input
                        className='otpInputs'
                        key={index}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => inputs.current[index] = el}
                        style={{ width: '3rem', height: '3rem', textAlign: 'center', marginRight: '0.5rem', fontWeight:600,borderRadius:5, border:'none'}}
                    />
                ))}
            </div>
        </div>
    );
};

export default OtpInput;