import React from 'react';
import './CheckMarks.css';

interface Props2 {
    width: number;
    height: number;
    color: 'success' | 'error' | 'primary' | 'warn' | 'light' | 'default';
}

const CheckMark2 = ({ width, height, color }: Props2) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`svg2-${color} svg2-box`} style={{ height, width }} viewBox="0 0 24 24">
            <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} stroke="none" >
                <circle className={`${color}-circle-outline2 circle-outline2`} cx="12" cy="12" r="11.5" />
                <circle className={`${color}-circle-fill2 circle-fill2`} cx="12" cy="12" r="11.5" />
                <polyline className={`${color}-tick2 tick2`} points="17,8.5 9.5,15.5 7,13" />
            </g>
        </svg>
    );
}

export default CheckMark2;