import React, { useState, useEffect } from 'react';
import './MainPage.css';
import Navbar from './../Components/Navbar/Navbar'; // Fix the casing of the file name
const API_URL = process.env.REACT_APP_FLASK_SERVER_URL;
function MainPage() {
  const [question, setQuestion] = useState<string>('');
  const [response, setResponse] = useState<string>('');

  // Handles the input field change with proper typing
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuestion(event.target.value);
  };

  // Handles sending the request and receiving the response with async/await
  const handleSubmit = async (): Promise<void> => {
    try {
      console.log("Started request");
      const queryParams: string = new URLSearchParams({ question }).toString();
      // const response = await fetch(`https://happyflask9748.com/responsellm?${queryParams}`);
      const response = await fetch(`${API_URL}/responsellm?${queryParams}`);

      const data: any = await response.json(); // Replace 'any' with a more specific type based on your expected response structure
      console.log(data);
      // Assuming the response is directly the string you want to display.
      // Adjust this line if your response structure is different
      setResponse(data["generation"]);

    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div className='mainpage-container'>
      <Navbar />
      <div className='left'>
        <input
          type="text"
          value={question}
          onChange={handleInputChange}
          placeholder="Ask a question"
        />
        <button className='submit' onClick={handleSubmit}>Submit</button>
      </div>
      <div className='right'>
        <p className='response'>Response: {response}</p> {/* Display the response */}
      </div>
    </div>
  );



}
const APIEndPoint = 'https://r7n7raaayb.execute-api.us-east-2.amazonaws.com/v1/hello'
const APIResult = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(APIEndPoint, {
          headers: { accept: "application/json" },
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const json = await response.json();
        setData(json);
      } catch (e: any) {
        setError(e);
      }
    };

    fetchData();
  }, []);

  if (error) return <p>{(error as Error).message}</p>;
  if (data) return <p>{(data as { message: string }).message}</p>; // Ensure your API response has a 'message' field; adjust as needed
  return null;
};
export default MainPage;