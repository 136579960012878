import { faGear, faRightFromBracket, faUserLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getSettings, SettingModel } from './../../ApiServices/SettingsApi';
import { persistor } from './../../Redux/Store';
import { jwtDecode } from "jwt-decode";
import {useLocation } from 'react-router-dom';

interface JwtPayload {
  exp: number;
}

interface Props {
  title: string;
  // userName:string;
}
const WithSidemenuHeader = ({ title, }: Props) => {
  const [settingInfo, setSettingInfo] = useState<SettingModel>({ _id: "", companyTitle: "", logo: "" });
  const [user, setUser] = useState({ firstName: "", lastName: "" });
  const location:any = useLocation();


  useEffect(() => {
    checkToken();
    getUserData();
  }, [])


  const checkToken = () => {
    const token = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`);
    if (token && isTokenExpired(token)) {
      if (location?.pathname != '/login' && location?.pathname != '/register' && location?.pathname != '/forgot-password') {
        logout();
      }
    }
  };

  const isTokenExpired = (token: string): boolean => {
    if (!token) {
      return true; // No token means it's "expired"
    }

    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decoded.exp < currentTime;
    } catch (error) {
      return true; // If there's an error decoding, consider the token expired
    }
  };

  const getUserData = async () => {
    const auth: any = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`);
    if (auth) {
      setUser(JSON.parse(auth || '{}'));

    }
  }


  const logout = async () => {
    localStorage.clear();
    persistor.purge().then(async () => {
      window.location.href = '/login';
      await persistor.purge();
    });
  }

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className='header-details QQ'>
        <div>
          <h5 className='fw-bold'>{title || 'Dashboard'}</h5>
        </div>
        <div className="spacer"></div>
        <div>
          {/* <p>Welcome Jhon</p> */}
          <div className="btn-group">
            <span className="btn btn-link text-secondary text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

              {capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.lastName)}
            </span>
            <ul className="dropdown-menu dropdown-menu-end shadow rounded-0">
              <li><Link to='/profile' className="dropdown-item"><FontAwesomeIcon icon={faUserLarge} className='me-2' /> Profile</Link></li>
              <li><Link to='/settings' className="dropdown-item"><FontAwesomeIcon icon={faGear} className='me-2' /> Setting</Link></li>
              <li><a href="#" onClick={logout} className="dropdown-item"><FontAwesomeIcon icon={faRightFromBracket} className='me-2' /> Logout</a></li>
            </ul>
          </div>

          {/* <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> */}
        </div>
      </div>
      <div className="spacer"></div>
      <div>

      </div>

    </>
  )
}

export default WithSidemenuHeader;