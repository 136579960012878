import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useSelector } from 'react-redux';

interface JwtPayload {
    exp: number;
}

interface Props {
    children: React.ReactNode;
}

const TokenCheck = ({ children }: Props) => {
    const isSpecialDomain: string = useSelector((state: any) => state.AppReducer.isSpecialDomain);
    const navigate = useNavigate();
    const location:any = useLocation();
    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`);
            if (isTokenExpired(token)) {
                if(location?.pathname!='/login' && location?.pathname!='/register' && location?.pathname!='/forgot-password'){
                    navigate('/login'); // Redirect to login
                }
            } else {
                switch (location.pathname) {
                    case '/login':
                            // navigate('/trust-center'); // Redirect to login
                        break;
                    default:
                        break;
                }
            }
        };

        // checkToken();

        // Listen for route changes
        return () => {
            if(isSpecialDomain=='FALSE'){
                checkToken();
            }
        };
    }, [navigate, location]);

    // Utility function to check if the token is expired
    const isTokenExpired = (token: string | null): boolean => {
        if (!token) {
            return true; // No token means it's "expired"
        }

        try {
            const decoded = jwtDecode<JwtPayload>(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decoded.exp < currentTime;
        } catch (error) {
            return true; // If there's an error decoding, consider the token expired
        }
    };

    return <>{children}</>;
};
export default TokenCheck;