import ApiFetch from '../Helper/FetchApi';
// import { } from '../Models/SecurityModel';
import Api from '../Helper/Constant';


/**@GET_SECURITY_QUESTION */
export const getAccountsList = async (pageNo: number, pageSize: number) => {
    return ApiFetch.fetchGet(`${Api.getAccounts}?page=${pageNo}&pageSize=${pageSize}`);
}

/**@GET_SECURITY_QUESTION */
export const getApprovedAccountsList = async (pageNo: number, pageSize: number) => {
    return ApiFetch.fetchGet(`${Api.getAccounts}?page=${pageNo}&pageSize=${pageSize}&pageType=approved`);
}

/**@ACCOUNT_STATUS_API */
export const accountStatusApi = async (id: string, data: any) => {
    return ApiFetch.fetchPut(`${Api.accountStatusApi}${id}`, JSON.stringify(data));
}

/**@DLETE_SECURITY_QUESTION */
export const DeleteaccountsApi = async (id: string) => {
    return ApiFetch.fetchDelete(`${Api.accountsDelete}${id}`, []);
}