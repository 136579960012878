const Validate = {
    Email:(email: string): boolean => {
        const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return re.test(email);
    },
    Phone:(phone: string): boolean => {
        const re = /^\+?[1-9]\d{1,14}$/;
        return re.test(phone);
    },
    Username:(username: string): boolean => {
        return username.length >= 3;
    },
    Password:(password: string): boolean => {
        return password.length >= 6;
    },
    Checkbox:(checked: boolean): boolean => {
        return checked;
    },
}
export default Validate;