import Api from "./../Helper/Constant";
import ApiFetch from "./../Helper/FetchApi";

/**@GET_PEOPLE_LIST */
export const getPeopleList = (page: number, pageSize: number) => {
    return ApiFetch.fetchGet(`${Api.peopleList}?page=${page}&pageSize=${pageSize}`);
}

/**@POST_PEOPLE */
export const savePeopleList = (reqBody: any) => {
    return ApiFetch.fetchPost(`${Api.peopleInvite}`, JSON.stringify(reqBody));
}

/**@DELETE_PEOPLE */
export const deletePeopleList = (id: any) => {
    return ApiFetch.fetchDelete(`${Api.peopleDelete}${id}`, JSON.stringify({}));
}


/**@PEOPLE_EMAIL_CHECK */
export const peopleInviteSignUpchk = (reqBody: any) => {
    return ApiFetch.fetchPost(`${Api.peopleInviteSignUp}`, JSON.stringify(reqBody));
}

/**@PEOPLE_CREATE_USER */
export const peopleCreateUser = (reqBody: any) => {
    return ApiFetch.fetchPost(`${Api.peopleCreateSignUp}`, JSON.stringify(reqBody));
}