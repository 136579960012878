import SideMenu from './../../Components/SideMenu'
import WithSidemenuHeader from './../../Components/WithSidemenuHeader'
import React, { useEffect, useState } from 'react'
import './Profile.css'

import { UserProfileModel } from './../../Models/UserProfileModel'
import { userProfile, updateUserProfile } from './../../ApiServices/userProfile'
import { getAuth } from './../../ApiServices/Auth.Service'
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { NotificationModel } from './../../Models/SecurityModel';
import CheckMark from './../../Components/CheckMark'

type ColorType='success' | 'default';
const Profile = () => {
  
  const [color, setColor] = useState<ColorType>('success');
  const [successMsg, setSuccessMsg] = useState('')
  const [inputValue, setInputValue] = useState('');

  const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
  const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<UserProfileModel>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    }
  });

  useEffect(() => {
    getUserProfile();
  }, [])

  /**@GET_PROFILE */
  const getUserProfile = async () => {
    const userId: string = getAuth()?._id || '';
    const result: any = await userProfile(userId);
    if (result) {
      reset({
        firstName: result?.data?.firstName,
        lastName: result?.data?.lastName,
        email: result?.data?.email,
      })
      setColor('success')
    }else{
      setColor('default')
    }
  }

  /**@PUT_UPDATE_PROFILE */
  const onSubmit: SubmitHandler<UserProfileModel> = async (data) => {
    setColor('default');
    setNotification({ type: 'none', msg: '' });
    const obj: any = data;
    const id: string = getAuth()?._id || '';
    const sendData: any = {
      firstName: obj.firstName,
      lastName: obj.lastName,
    }

    const result = await updateUserProfile(id, sendData); /**@UPADTE_API */
    if (result && result.status == true) {
      setNotification({ type: 'success', msg: `${(data?.firstName ? 'Updated' : 'Added')} Successfully` })
      setColor('success')    
      
    } else {
      setColor('default');
      setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
    }
    
  }
  
  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };


  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title={'Profile'} />
        <div className="router-container">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 mt-5">
              <div className="card">
                <div className="card-body px-lg-4">
                  <form method="post" autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-3">
                      <label htmlFor="Email" className='fw-bold'>Email</label>
                      {/* <input type="text" className='form-control-cust' /> */}
                      <Controller
                        name={`email`}
                        control={control}
                        defaultValue={''}
                        // render={({ field }) => <input {...field} />}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => <input {...field}
                          className="form-control-cust"
                          placeholder=" " {...field} readOnly />}
                      />
                      {errors.firstName && (
                        <div className="sc-input-err-msg">{errors.firstName?.message}</div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label htmlFor="firstName" className='fw-bold'>First Name</label>
                      {/* <input type="text" className='form-control-cust' /> */}
                      <Controller
                        name={`firstName`}
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => <input type="text" className="form-control-cust"
                          placeholder=" " {...field} />}
                      />
                      {errors.firstName && (
                        <div className="sc-input-err-msg">{errors.firstName?.message}</div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label htmlFor="lastName" className='fw-bold'>Last Name</label>
                      {/* <input type="text" className='form-control-cust' /> */}
                      <Controller
                        name={`lastName`}

                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => <input type="text" className="form-control-cust"
                          placeholder=" " {...field} />}
                      />
                      {errors.lastName && (
                        <div className="sc-input-err-msg">{errors.lastName?.message}</div>
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button className='save-btn px-4'>Update</button>
                      <span className='mt-3 ms-2'>
                        <CheckMark height={20} width={20} color={color} />
                        </span>
                    </div>
                  </form>
                </div>
              </div>

              <div className="text-center mt-3">
                {successMsg && <p className='text-success'>{successMsg}</p>}
              </div>

            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Profile;

function setInputValue(value: any) {
  throw new Error('Function not implemented.')
}