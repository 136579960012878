import React, { useState, useEffect } from 'react';
import { toastManager } from './ToastManager';
import './ToastDialog.css';
import CheckMark from './../../Components/CheckMark';

const ToastDialog: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [onCancel, setOnCancel] = useState<() => void | null>();

    useEffect(() => {
        toastManager.setCallbacks({
            openDialog: ({ type, title, message, onCancel }) => {
                setMessage(message);
                setTitle(title);
                setType(type);
                setOnCancel(() => () => {
                    if (onCancel) onCancel();
                    closeDialog();
                });
                setIsOpen(true);
            },
            closeDialog: () => {
                setIsOpen(false);
            }
        });
    }, []);

    const closeDialog = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="toast-dialog">
            <div className={`toast-dialog-content ${type}`}>
                <button onClick={closeDialog} className="close-button">+</button>
                <div className="d-flex flex-row align-items-center">
                    <div className="checkmark-container">
                        <CheckMark color={'light'} height={30} width={30} />
                    </div>
                    <div className="message-container">
                        <h4 className="toast-title">{title}</h4>
                        <p className="toast-message">
                            {message.split('. ').map((sentence, index) => (
                                <React.Fragment key={index}>
                                    {sentence}
                                    {index < message.split('. ').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToastDialog;