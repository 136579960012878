import React, { useEffect, useState, useRef } from 'react';
import WithSidemenuHeader from '../../Components/WithSidemenuHeader';
import SideMenu from '../../Components/SideMenu';
import './EnterpriseApps.css';
import { useNavigate } from "react-router-dom";
import Download from './Download/Download';
import FilesList from './FilesList/FilesList';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EnterpriseService from '../../ApiServices/Enterprise.Service';
import { CompanysModal } from '../../Models/Companys';
import { Controller, useForm } from 'react-hook-form';
import { getCompanyList, getComplianceData, getQuestionnairesData, setSelectedQuestion, deleteCompanyRequest, getClientDetails } from "../../Redux/Actions/SagaAction";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CheckMark from '../../Components/CheckMark';
import { confirmationManager } from '../../Components/ConfirmationDialog/ConfirmationManager';

const EnterpriseVendorDetails: React.FC = (props) => {
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [listType, setListType] = useState<string>('');
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modelData, setmodelData] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedVendor = useRef<any>(null);
  //model
  const data = useSelector((state: any) => state.AppReducer);
  const selectedQuestion: any = useSelector((state: any) => state.AppReducer.selectedQuestion);
  const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);

  useEffect(()=>{
    if(EnterpriseService?.getCompanyInfo && EnterpriseService?.getCompanyInfo?._id){
      selectedVendor.current=EnterpriseService?.getCompanyInfo?._id;
    }
  },[])

  // Function to handle button click
  const handleShowLoadingPage = (data: any, listType: string): void => {
    if (listType == 'only-doument-download') {
      setListType('only-doument-download')
      if (data && data['files'] && data['files'].length) {
        dispatch(setSelectedQuestion({
          questions: [],
          fileName: data['files'][0]['fileName'],
          fileId: data['files'][0]['_id'],
          company_id: data['company_id'],
          _id: data['_id'],
          createdAt: data['createdAt'],
        }));
      }
    } else {
      setListType('')
    }

    const { item, itemType } = data;
    if (item && item['files'] && item['files'].length) {
      dispatch(setSelectedQuestion({
        questions: item['files'][0]['questions'],
        fileName: item['files'][0]['fileName'],
        fileId: item['files'][0]['_id'],
        company_id: item['company_id'],
        _id: item['_id'],
        createdAt: item['createdAt'],
      }));
    }

    if (itemType == 'documents') {
      return;
    }

    setShow(true);
    setShowLoadingPage(true);
    setTimeout(() => {
      setShowLoadingPage(false);
      setShowDownloadPage(true);
    }, 1000); // 20000 milliseconds = 20 seconds
  };

  // Function to handle file process showing loading
  const fileProcessShowLoader = (data: any): void => {
    if (data?.modelShow) {
      setmodelData(data);
    }
  };


  //Model close 
  const modelhandleClose = () => {
    if (modelData?.itemType != 'documents' && modelData?.checkmark) {
      handleShowLoadingPage(modelData, modelData?.itemType);
      setTimeout(() => {
        setmodelData({})
      }, 10);
    } else {
      setmodelData({})
    }
  }

  useEffect(() => {
    const item: any = (data.questionArrayList[data.questionArrayList.length - 1]);
    if (item && item['files']) {
      dispatch(setSelectedQuestion({
        questions: item['files'][0]['questions'],
        fileName: item['files'][0]['fileName'],
        fileId: item['files'][0]['_id'],
        company_id: item['company_id'],
        _id: item['_id'],
        createdAt: item['createdAt']
      }));
    }
  }, [data.questionArrayList])


  /**
   * Refreshes the questionnaires data by dispatching an action with the currently selected vendor.
  */
  const refresh = async () => {
    dispatch(getQuestionnairesData(selectedVendor.current));
  }

  /**
   * BACK Navigation
  */
  const goBack = async () => {
    navigate(-1);
    dispatch(getComplianceData(''));
    dispatch(getQuestionnairesData(''));
  }

  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title='Vendor Details' />
        <div className="router-container">
          <div style={{ marginTop: 8 }}>
            <div className="back-button pointer" onClick={() =>goBack()}>
                <img className='back-arrow' src={process.env.PUBLIC_URL + '/assets/imgs/back-arrow.png'} alt="back-icon" style={{ width: '16px', marginRight: '8px' }} />
                <span>&nbsp;{EnterpriseService?.getCompanyInfo && EnterpriseService?.getCompanyInfo?.companyName ? EnterpriseService?.getCompanyInfo.companyName : 'NA'}</span>
            </div>
            
          </div>
          <div className="enterprise-container mt-3">
            <div className="main">
              <div className="main-left">
                <div className="heading-container"></div>
                <FilesList listType="documents" companyName={selectedCompanyName} files={data.complianceArrayList} refresh={() => dispatch(getComplianceData(selectedVendor.current))} handleShowLoadingPage={handleShowLoadingPage} fileProcessShowLoader={fileProcessShowLoader} onClick={handleShow} companyId={selectedVendor.current} />
                <div className="horizontal-divider" />
                <FilesList listType="questions" companyName={selectedCompanyName} files={data.questionArrayList} refresh={() => refresh()} handleShowLoadingPage={handleShowLoadingPage} fileProcessShowLoader={fileProcessShowLoader} onClick={handleShow} companyId={selectedVendor.current} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={modelData?.checkmark ? true : false}
        onHide={modelhandleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{modelData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body d-flex justify-content-center align-items-center gap-2"
          style={{ lineHeight: '20px' }}>
          <span className="text-capitalize">{modelData?.message}</span>
          <span><CheckMark height={20} width={20} color={'success'} /></span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modelhandleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

      <Offcanvas show={show} placement={'end'} onHide={handleClose} style={{ width: '40%' }}>
        <Offcanvas.Header closeButton className='shadow-sm'>
          <Offcanvas.Title>
            <div className="d-flex">
              <img src={process.env.PUBLIC_URL + 'assets/imgs/logo-secondary.png'} alt="logo" style={{ width: '72px' }} />
              <h2 className='ms-3'>{selectedQuestion?.fileName ? selectedQuestion?.fileName : ''}</h2>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="main-right">
            <Download loadingTime={1000} listType={listType} showDownloadPage={showDownloadPage} />
            {/* {showDownloadPage && <Download loadingTime={1000} listType={listType} />}
            {showLoadingPage && <LoadingPage />} */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </main>
  )
}
export default EnterpriseVendorDetails;