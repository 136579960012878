interface ConfirmationCallbacks {
    openDialog?: (params: ConfirmationParams) => void;
    closeDialog?: () => void;
}

interface ConfirmationParams {
    heading?: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
    show?: boolean;
}

class ConfirmationManager {
    private callbacks: ConfirmationCallbacks = {};

    setCallbacks(callbacks: ConfirmationCallbacks) {
        this.callbacks = callbacks;
    }

    requestConfirmation(params: ConfirmationParams) {
        if (this.callbacks.openDialog) {
            this.callbacks.openDialog(params);
        }
    }

    closeConfirmation() {
        if (this.callbacks.closeDialog) {
            this.callbacks.closeDialog();
        }
    }
}

export const confirmationManager = new ConfirmationManager();
