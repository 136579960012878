import React from 'react'

const PrivacyPolicy = () => {
    return (
        <main>
            <div className="header sticky-top" style={{ background: '#3c3232' }}>
                <img src={process.env.PUBLIC_URL + 'assets/imgs/targhee-logo.png'} alt="watermark" className="watermark" style={{ position: 'inherit', width: '200px', padding: '10px' }} />
            </div>

            <div className="p-5 bg-light">
                <div className="container">
                    <h1 className='text-dark fs-2'>Privacy Policy</h1>
                    <p>Last updated Aug 14, 2024</p>
                </div>
            </div>

            <div className="container my-5 text-light">
                
Targhee Security, Inc. (“Targhee,” “we,” “us,” or “our”) has prepared this Privacy Policy to explain (1) what personal information we collect, (2) how we use and share that information, and (3) your choices concerning our privacy and information practices.

Applicability of this Privacy Policy
We provide a security portal to assist customers in security assessment and in completing security questionnaires (the “Services”). This Privacy Policy applies to personal information that we collect in connection with the Services, our website(s) (including <a href="https://www.targheesec.com/">https://www.targheesec.com/</a>), and any other products and/or services that link to this Privacy Policy.  

If you are a customer of Targhee, this Privacy Policy does not apply to personal information that we process on your behalf (if any) as your service provider. Such personal information shall instead be governed by the terms and conditions of the separate customer agreement or terms of service that you have agreed to with Targhee. In addition, our Services are designed for businesses and are not intended for personal, family, or household use. Accordingly, we treat all personal information covered by this Privacy Policy as pertaining to individuals acting as business representatives, rather than in their personal capacity.  

Personal information we collect
Information you provide to us:
	•	Business Contact Information: If you are a representative of one of our actual or prospective customers, suppliers or business partners, we may collect personal information about you (such as your name, contact details and role) when entering into an agreement with your company or otherwise the during the course of our relationship with your company.  
	•	Feedback or correspondence, such as information you provide when you contact us with questions, feedback, reviews, or otherwise correspond with us online.
	•	Usage information, such as information about how you use the Services and interact with us.
	•	Marketing information, such as your preferences for receiving communications about our activities, services, and publications, and details about how you engage with our communications.
	•	Other information that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.
Information we obtain from third parties.  We may obtain your personal information from other third parties, such as marketing partners, publicly-available sources and data providers, for the purposes of marketing products and services that may interest you, delivering personalized communications, and other similar activities.  In addition, we may maintain pages on social media platforms, such as Facebook, Twitter, Instagram, and other third-party platforms. When you visit or interact with our pages on those platforms, the platform provider’s privacy policy will apply to your interactions and their collection, use and processing of your personal information. You or the platforms may provide us with information through the platform, and we will treat such information in accordance with this Privacy Policy. 
Automatic data collection. We and our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with our Services, our communications and other online services, such as:

	•	Device data, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 4G), and general location information such as city, state or geographic area.
	•	Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, browsing history, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them. We may use third party tools to assist with capturing online activity data.
	•	Email Open/Click Information. We may use pixels in our email campaigns that allow us to collect your email and IP address as well as the date and time you open an email or click on any links in the email that we may send to you.
We use the following tools for automatic data collection:
	•	Cookies, which are text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.
	•	Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.
	•	Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.
How we use your personal information
To operate our Services:
	•	Provide, operate, maintain, secure and improve our Services.
	•	Provide information about our Services.
	•	Communicate with you about our Services, including by sending you announcements, updates, security alerts, and support and administrative messages.
	•	Respond to your requests, questions and feedback.
Marketing and advertising. We may from time-to-time send you direct marketing communications as permitted by law, including, but not limited to, notifying you of special promotions, offers and events via email. You may opt out of our marketing communications as described in the “Opt out of marketing communications” section below.
For research and development. We may use your personal information for research and development purposes, including to analyze and improve our Services and our business. As part of these activities, we may create aggregated, de-identified, or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve our Services and promote our business.
Compliance and protection. We may use personal information to:
	•	Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.
	•	Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims).
	•	Audit our internal processes for compliance with legal and contractual requirements and internal policies.
	•	Enforce the terms and conditions that govern our Services.
	•	Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.
How we share your personal information
Service providers. We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate our Services (such as lawyers, bankers, auditors, insurers, and providers that assist with hosting, analytics, email delivery, marketing, and database management).
Authorities and others. We may disclose your personal information to law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.
Business transfers. We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. In such a case, we will make reasonable efforts to require the recipient to honor this Privacy Policy.
Affiliates: We may share personal information with our current and future affiliates, meaning an entity that controls, is controlled by, or is under common control with us. Our affiliates may use the personal information we share in a manner consistent with this Privacy Policy.
Cross-border processing of your personal information
We are headquartered in the United States. To provide and operate our services, it is necessary for us to process your personal information in the United States. If we transfer personal information across borders such that we are required to apply appropriate safeguards to personal information under applicable data protection laws, we will do so. Please contact us for further information about any such transfers or the specific safeguards applied.
Your choices
Opt out of marketing communications. You may opt out of email communications by following the opt-out or unsubscribe instructions at the bottom of the email.  
Online tracking opt-out. There are a number of ways to opt out of having your online activity and device data collected through our Services, which we have summarized below: 
	•	Blocking cookies in your browser. Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org.
	•	Using privacy plug-ins or browsers. You can block our Services from setting cookies used for interest-based ads by using a browser with privacy features or installing browser plugins like Privacy Badger, DuckDuckGo, Ghostery or uBlock Origin, and configuring them to block third party cookies/trackers.
Note that because these opt-out mechanisms are specific to the device or browser on which they are exercised, you will need to opt-out on every browser and device that you use.
Do Not Track. Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.
Data Retention
We may retain your personal information for as long as it is reasonably needed in order to maintain and expand our relationship and provide you with our services; in order to comply with our legal and contractual obligations; or to protect ourselves from any potential disputes. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of such information, the potential risk of harm from unauthorized use or disclosure of such information, the purposes for which we process it, and the applicable legal requirements.  
Security practices
We use reasonable organizational, technical and administrative measures designed to protect against unauthorized access, misuse, loss, disclosure, alteration and destruction of personal information we maintain. Unfortunately, data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee the security of personal information. In the event that we are required to notify you about a situation involving your data, we may do so by email or telephone to the extent permitted by law.
Children 
Our Services are not intended for children, and we do not collect personal information from them. We define “children” as anyone under 18 years old. If we learn we have collected or received personal information from a child without verification of parental consent, we will delete the information. If you believe we might have any information from or about a child, please contact us via the contract information noted below.
Changes to this Privacy Policy
We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on our Services. We may also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through our Services.
Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on our Services (or as otherwise indicated at the time of posting). In all cases, your continued use of the Services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.
How to contact us
If you have any questions or concerns, you can reach us by email at <a href= "mailto: info@targheesec.com"> info@targheesec.com </a>.
                    
            </div>
        </main>
    )
}

export default PrivacyPolicy