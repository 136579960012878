import React from 'react'

const TermsOfService = () => {

    return (
        <main>
            <div className="header sticky-top" style={{background:'#3c3232'}}>

            <img src={process.env.PUBLIC_URL + 'assets/imgs/targhee-logo.png'} alt="watermark" className="watermark" style={{position: 'inherit', width:'200px', padding: '10px'}} />
            </div>
            <div className="p-5 bg-light">
                <div className="container">
                    <h1 className='text-dark fs-2'>Website Terms of Use</h1>
                    <p>Last updated Aug 14, 2024</p>
                </div>
            </div>

            <div className="container my-5 text-light">
                <p className='term-para'>
	1.	Acceptance of the Terms and Conditions.
	1.1.	Targhee Security, Inc. (“Targhee,” “we,” “us” or “our”) provides and makes available this website (the “Site”) subject to these Terms of Use (this “Agreement”).  Please read this Agreement carefully.  By accessing, browsing or otherwise using the Site, you acknowledge that you have read, understood, and agree to be bound by this Agreement.  If you do not accept the terms and conditions of this Agreement, you shall not access, browse or use the Site.  You understand and agree that your use of our products and services, including our security portal and AI security questionnaire tool (“Services”) shall not be governed by this Agreement, but rather by your company’s or organization’s agreement with Targhee covering such Services.  However, please note that your access to and use of the Site and any Services is also subject to Targhee’s Privacy Policy located at  <a href='https://targheesec.ai/privacy-policy'>https://targheesec.ai/privacy-policy</a>.
	1.2.	You understand and agree that we may change this Agreement at any time without prior notice.  You may read a current, effective copy of this Agreement at <a href='https://targheesec.ai/terms-of-service'>https://targheesec.ai/terms-of-service</a>. The revised terms and conditions will become effective at the time of posting.  Any use of the Site after such date shall constitute your acceptance of such revised terms and conditions. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing and otherwise using the Site.
	2.	Use of the Site.
	2.1.	This Site contains material, including but not limited to artificial intelligence tools, software, text, graphics and images (collectively referred to as the “Content”).  We may own the Content or portions of the Content may be made available to us through arrangements that we have with third-parties.  The Content is protected by United States and foreign intellectual property laws.  Unauthorized use of the Content may result in violation of copyright, trademark, and other laws.  You have no rights in or to the Content, and you will not copy the Content and will only access and use the Content for your personal purposes.  You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose.  The use or posting of any of the Content on any other website or computer network for any purpose is expressly prohibited. If you violate any part of this Agreement, your right to access and/or use the Content and Site shall automatically terminate.
	2.2.	The trademarks, service marks, and logos of Targhee (the “Targhee Trademarks”) used and displayed on this Site are registered and unregistered trademarks or service marks of Targhee.  Other company, product, and service names located on the Site may be trademarks or service marks owned by third-parties (the “Third-Party Trademarks”, and, collectively with the Targhee Trademarks, the “Trademarks”).  Nothing on this Site or in this Agreement should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Site without the prior written consent of Targhee specific for each such use.  The Trademarks may not be used to disparage Targhee or the applicable third-party, Targhee’s or third-party’s products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks.  Use of any Trademarks as part of a link to or from any website is prohibited without Targhee’s prior written consent.  All goodwill generated from the use of any Targhee Trademark shall inure to Targhee’s benefit.
	2.3.	You agree not to: (a) take any action that imposes an unreasonable load on the Site’s infrastructure, (b) use any device, software or routine to interfere or attempt to interfere with the proper working of the Site or any activity being conducted on the Site, (c) attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or making up the Site, (d) use any “deep link”, “page scrape”, “robot”, “spider”, or other automatic device, program, script, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any portion of the Site or in any way reproduce or circumvent the navigational structure or presentation of the Site to obtain or attempt to obtain any materials, documents, or information through any means not purposefully made available through the Site, (e) delete or alter any material posted on the Site by Targhee or any other person or entity, (e) attempt to gain unauthorized access to any portion or feature of the Site, including without limitation, the account of any other authorized user or any other systems or networks connected to the Site, by hacking, password “mining” or any other illegitimate or prohibited means or (e) frame or link to any of the materials or information available on the Site.
	2.4.	Certain elements of the Site are protected by trade dress, trademark, unfair competition, and other state and federal laws and may not be copied or imitated in whole or in part, by any means, including but not limited to, the use of framing or mirrors, except as otherwise expressly permitted by Section 2.1 of the Agreement. None of the Content for this Site may be retransmitted without the express written consent from Targhee for each and every instance.
	2.5.	You may from time to time provide suggestions, comments for enhancements or functionality or other feedback (“Feedback”) to us with respect to the Site or Content.  We shall have full discretion to determine whether or not to proceed with the development or implementation of any Feedback.  You hereby grants Targhee a royalty-free, fully paid up, worldwide, transferable, sublicenseable, irrevocable, perpetual license to (a) copy, distribute, transmit, display, perform, and create derivative works of the Feedback; and (b) use the Feedback and/or any subject matter thereof, including without limitation, the right to develop, manufacture, have manufactured, market, promote, sell, have sold, offer for sale, have offered for sale, import, have imported, rent, provide and/or lease products or services which practice or embody, or are configured for use in practicing, the Feedback and/or any subject matter of the Feedback.
	3.	Third Party Materials
	3.1.	The Website may contain certain materials provided by a third party.  Under no circumstances will Targhee be liable in any way for any content or materials of any third parties (including users), including for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content.
	4.	Limitation of Liability and Disclaimer of Warranties.
	4.1.	TARGHEE, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS (COLLECTIVELY, THE “TARGHEE PARTIES“) MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE SITE OR CONTENT, INCLUDING BUT NOT LIMITED TO ITS ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS OR RELIABILITY.  THE TARGHEE PARTIES SHALL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS OF THE SITE OR CONTENT OR ANY OTHER INFORMATION CONVEYED TO THE USER OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE.  YOU AGREE THAT YOU USE THE SITE AND THE CONTENT AT YOUR OWN RISK.
THE TARGHEE PARTIES DO NOT WARRANT THAT THE SITE WILL OPERATE ERRORFREE OR THAT THE SITE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SITE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO TARGHEE PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS.

THE SITE AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. THE TARGHEE PARTIES DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NONINFRINGEMENT OF THIRD PARTIES RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. 

	4.2.	IN NO EVENT SHALL ANY TARGHEE PARTY BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE SITE AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF SUCH TARGHEE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
	4.3.	SOME STATES DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.  ACCORDINGLY, IN SUCH STATES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU, AND THE LIABILITY OF THE TARGHEE PARTIES SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
	4.4.	IF YOU ARE FROM NEW JERSEY, THE FOREGOING SECTIONS 4.1 AND 4.2 AND SECTION 5 BELOW ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY.  IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
	5.	Indemnification.  To the extent permitted under applicable law, you agree to defend, indemnify, and hold harmless the Targhee Parties from and against any claims, actions or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from your breach of this Agreement or your access to, use or misuse of the Content or Site.  Targhee shall provide notice to you of any such claim, suit, or proceeding.  Targhee reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting Targhee’s defense of such matter.
	6.	Termination of the Agreement.
	6.1.	Targhee reserves the right, in its sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Site or the Content at any time and for any reason without prior notice or liability. Targhee reserves the right to change, suspend, or discontinue all or any part of the Site or the Content at any time without prior notice or liability.
	6.2.	Sections 2 (Use of the Site), 4 (Limitation of Liability and Warranty), 5 (Indemnification), 6 (Termination of Agreement), and 9 (Miscellaneous) shall survive the termination of this Agreement.  
	7.	User Must Comply with Applicable Laws.
	7.1.	This Site is hosted in the United States.  We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States.  If you access the Site or the Content from outside of the United States, you do so at your own risk.  Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.
	7.2.	The United States controls the export of products and information. You expressly agree to comply with such restrictions and not to export or re-export any of the Content to countries or persons prohibited under the export control laws. By downloading the Content, you are expressly agreeing that you are not in a country where such export is prohibited or are a person or entity for which such export is prohibited. You are solely responsible for compliance with the laws of your specific jurisdiction regarding the import, export, or re-export of the Content.
	8.	U.S. Government Restricted Rights.  The Content is provided with “RESTRICTED RIGHTS.”  Use, duplication, or disclosure by the Government is subject to the restrictions contained in 48 CFR 52.227-19 and 48 CFR 252.227-7013 et seq. or its successor.  Use of the Site or Content by the Government constitutes acknowledgement of our proprietary rights in the Site and Content.
	9.	Miscellaneous.  
	9.1.	This Agreement is governed by the internal substantive laws of the State of California, without respect to its conflict of laws provisions.  If any provision of this Agreement is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.  Failure of Targhee to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement.  No waiver shall be effective against Targhee unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by Targhee and you, this Agreement constitutes the entire agreement between you and Targhee with respect to the subject matter, and supercedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter.  The section headings are provided merely for convenience and shall not be given any legal import.  We may freely assign our rights under this Agreement.  This Agreement will inure to the benefit of our successors and assigns.  Any information submitted or provided by you to the Site might be publicly accessible.  Important and private information should be protected by you.  
                </p>
                
                
            </div>
        </main>
  )
}

export default TermsOfService;