import { createContext } from 'react';
interface Props {
  data: ElementItemProps;
  setData: (data: ElementItemProps) => void;
  reload: (type:string) => void;
}

interface ElementItemProps {
    complianceList: any[];
    securityList: any[];
    reloadType:string;
}

const ReactContext = createContext<Props | undefined>(undefined);
export default ReactContext;