import React from 'react';
import ColorMapping from './ColorsMapping';
import './Avatar.css'; // CSS for avatar styling

const Avatar = ({ username }:any) => {
  const initial = username.trim().charAt(0);
  const backgroundColor = ColorMapping(initial);
  return (
    <div className="avatar" style={{ backgroundColor }}>
      {initial}
    </div>
  );
};

export default Avatar;