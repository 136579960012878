import React, { useState, FormEvent } from 'react';

interface FormData {
  firstName: string;
  lastName: string;
  jobTitle: string;
  workEmail: string;
  companyName: string;
  relationshipWithLinkedIn: string;
  countryRegion: string;
  termsAgreed: boolean;
  updatesAgreed: boolean;
}

const FormComponent: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: 'a',
    lastName: 'a',
    jobTitle: 'a',
    workEmail: 'a@gmail.com',
    companyName: 'a',
    relationshipWithLinkedIn: 'a',
    countryRegion: 'a',
    termsAgreed: false,
    updatesAgreed: false
  });

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   });
  // };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:3500/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form data saved successfully');
        // Clear the form after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          jobTitle: '',
          workEmail: '',
          companyName: '',
          relationshipWithLinkedIn: '',
          countryRegion: '',
          termsAgreed: false,
          updatesAgreed: false,
        });
      } else {
        alert('Error saving form data');
      }
    } catch (error) {
      alert('Error saving form data');
    }
  };

  return (
    <>
    {/* <form onSubmit={handleSubmit}>
      <div>
        <label>
          First name:
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
        </label>
      </div>
      <div>
        <label>
          Last name:
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
        </label>
      </div>
      <div>
        <label>
          Job title:
          <input type="text" name="jobTitle" value={formData.jobTitle} onChange={handleChange} required />
        </label>
      </div>
      <div>
        <label>
          Work email:
          <input type="email" name="workEmail" value={formData.workEmail} onChange={handleChange} required />
        </label>
      </div>
      <div>
        <label>
          Company name:
          <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
        </label>
      </div>
      <div>
        <label>
          Relationship with LinkedIn:
          <select name="relationshipWithLinkedIn" value={formData.relationshipWithLinkedIn} onChange={handleChange} required>
            <option value="" disabled>Select...</option>
            <option value="a" >a</option>
            
          </select>
        </label>
      </div>
      <div>
        <label>
          Country / Region:
          <select name="countryRegion" value={formData.countryRegion} onChange={handleChange} required>
            <option value="" disabled>Select...</option>
            <option value="a" >a</option>
            
          </select>
        </label>
      </div>
      <div>
        <label>
          <input type="checkbox" name="termsAgreed" checked={formData.termsAgreed} onChange={handleChange} required />
          I have read and agree to the Terms of Service and Privacy Policy.
        </label>
      </div>
      <div>
        <label>
          <input type="checkbox" name="updatesAgreed" checked={formData.updatesAgreed} onChange={handleChange} />
          I would like to get updates via email.
        </label>
      </div>
      <button type="button" onClick={() => window.history.back()}>Back</button>
      <button type="submit">Submit Request</button>
    </form> */}
    </>
  );
};

export default FormComponent;

