import React, { useEffect, useState } from 'react'
import { getNdadata, } from './../../ApiServices/SettingsApi'


const NDA = () => {

    const [inputValue, setInputValue] = useState<any>([]);

    useEffect(() => {
        getNDA();
    }, [])

    /**@GET_NDA */
    const getNDA = async () => {
        const result: any = await getNdadata(true);
        if (result) {
            setInputValue(result?.data)
        }
    }

    const convertToLocalTime = (datetime: any) => {
        const date = new Date(datetime);
        // Format options to get the desired format
        const options:any = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        // Convert to local date and format it
        const localDate = date.toLocaleDateString(undefined, options);

        return localDate;
    }

    return (
        <main>
            <div className="header sticky-top" style={{ background: '#3c3232' }}>

                <img src={process.env.PUBLIC_URL + 'assets/imgs/targhee-logo.png'} alt="watermark" className="watermark" style={{ position: 'inherit', width: '200px', padding: '10px' }} />
            </div>
            <div className="p-5 bg-light">
                <div className="container">
                    <h1 className='text-dark fs-2'>Non-Disclosure Agreement</h1>
                    <p>Last updated {convertToLocalTime(inputValue?.updatedAt)}</p>
                </div>
            </div>

            <div className="container my-5 text-light">
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {inputValue?.description}
                </p>

            </div>
        </main>
    )
}

export default NDA;