import React, { useState } from 'react';
import SideMenu from './../../Components/SideMenu';
import './Dashboard.css';
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import FormComponent from './FormComponent';
const API_URL = process.env.REACT_APP_FLASK_SERVER_URL;

const Dashboard: React.FC = () => {
    const [file, setFile] = useState<any>(null)

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const onFileUpload = async () => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            try {
                const response = await fetch(`${API_URL}/download-questionnare`, {
                    method: 'POST',
                    body: formData,
                    // Headers are not necessary here as 'fetch' will automatically set the
                    // 'Content-Type' to 'multipart/form-data' with the proper 'boundary' when
                    // a FormData object is the body.
                });
                const responseData = await response.json();
                if (response.ok) {
                    console.log('File uploaded successfully', responseData);
                } else {
                    console.error('Failed to upload file', responseData);
                }
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
    };

    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'Dashboard'} />
                <div className="router-container">
                    <input type="file" onChange={onFileChange} />
                    <button onClick={onFileUpload}>Upload!</button>
                </div>
            </section>
            <FormComponent />

        </main>
    )
}

export default Dashboard;