import React, { useEffect, useState } from 'react';
import './Review.css';
import { useNavigate, useParams } from 'react-router-dom';
import WithSidemenuHeader from '../../../Components/WithSidemenuHeader';
import SideMenu from '../../../Components/SideMenu';
import CheckMark from './../../../Components/CheckMark';
import { useDispatch, useSelector } from 'react-redux';
import { enterprseUpdateQuestion, setReviewCount } from './../../../Redux/Actions/SagaAction';
import EnterpriseService from './../../../ApiServices/Enterprise.Service';
import { S3DownloadForEnterprise } from './../../../ApiServices/S3Bucket';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

interface QuestionProps {
    reviewstatus: boolean;
    llmstatus: boolean;
    llmReviewRespons: boolean;
    question: string;
    answer: string;
    status: string;
    user_id: null;
    documentName: null;
    bucketName: null;
    retrievedNarrative:string,
    fileAndFolder: [];
    _id: string;
    answerHistory?:[]
}

interface CountProps {
    countOfApproved: number;
    countOfReview: number;
}

function Review() {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [answer, setAnswer] = useState('');
    const [answerLastModified, setanswerLastModified] = useState('');
    const [comment, setComment] = useState('');
    const [answerError, setAnswerError] = useState('');
    const [commentError, setCommentError] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [activeTab, setActiveTab] = useState(1)
    const [selectedQuestionData, setSelectedQuestionData] = useState<QuestionProps>()
    const [commentList, setCommentList] = useState<any[]>([])
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const selectedQuestion: any = useSelector((state: any) => state.AppReducer?.selectedQuestion);
    const countReviewItem: any = useSelector((state: any) => state.AppReducer.countReviewItem);

    const authData: any = useSelector((state: any) => state.AppReducer.authData);
    const companyList: any = useSelector((state: any) => state.AppReducer.companyList);

    /**
    * Updates the answer, selected question data, question comments, question count,
    * and company name when a question is selected.
    */
    useEffect(() => {
        if (selectedQuestion && selectedQuestion['questions'] && selectedQuestion['questions'].length) {
            setanswerLastModified(selectedQuestion['questions'][0]['updatedAt'])
            setAnswer(selectedQuestion['questions'][0]['answer']);
            setSelectedQuestionData(selectedQuestion['questions'][0]);
            getQuestionComment(selectedQuestion['questions'][0]['_id']);
            const company: any = companyList.filter((item: any) => item?._id == selectedQuestion.company_id);
            if (company && company.length) {
                setCompanyName(company[0]['companyName']);
            }
        }
    }, []);


    /**
     * Handles the click event on a tab, setting the active tab to the selected index.
     * @param indexTab - The index of the tab that was clicked.
    */
    const handleTabsClick = (indexTab: number) => {
        setActiveTab(indexTab)
    }

    /**
     * Handles the selection of a question, updating the active index, answer, selected question data,
     * and fetching the comments for the selected question.
     * @param index - The index of the selected question.
     * @param answerValue - The data of the selected question.
    */
    const handleSelect = (index: number, answerValue: any) => {
        console.log(answerValue, 'answerValue');
        
        setanswerLastModified(answerValue?.updatedAt)
        setActiveIndex(index);
        setAnswer(answerValue.answer);
        setSelectedQuestionData(answerValue);
        getQuestionComment(answerValue._id);
    }

    /**
     * Fetches the list of comments for a given question and updates the comment list state.
     * @param questionId - The ID of the question for which comments are to be fetched.
    */
    const getQuestionComment = async (questionId: string) => {
        setCommentList([]);
        const result = await EnterpriseService.getCommentList(questionId);
        if (result && result.data && result.data.length) {
            setCommentList(result.data.reverse())
        }
    }


    /**
    * Updates the question and comment, dispatches the update action, and refreshes the comment list and question count.
    */
    const onUpdateQuestionaOnly = () => {
        if (!answer) {
            return setAnswerError('Answer field is required');
        }
        setAnswerError('');
        const obj: any = {
            "companyId": selectedQuestion.company_id,
            "fileId": selectedQuestion.fileId,
            "questionId": selectedQuestionData?._id,
            "answer": answer,
            "reviewstatus": selectedQuestionData?.reviewstatus,
            "llmstatus": ((selectedQuestionData?.llmReviewRespons == false && selectedQuestionData?.llmstatus) ? true : selectedQuestionData?.reviewstatus ? selectedQuestionData?.llmstatus : false),
            "userId": authData._id,
            "userName": `${authData.firstName} ${authData.lastName}`
        }

        const answerHistory:any[]= selectedQuestionData?.answerHistory || [];

        // Check if the current answer is not already in answerHistory
        if (selectedQuestionData?.answer !== answer) {
            answerHistory.push({ answer: selectedQuestionData?.answer, createdAt: new Date(), name:`${authData.firstName} ${authData.lastName}` });
        }

        dispatch(enterprseUpdateQuestion(obj, (activeIndex - 1),  answerHistory));
        setComment('');
        setSelectedQuestionData((prev:any)=>({...prev, answer:answer}));
    }


    /**
    * Updates the comment only, dispatches the update action, and refreshes the comment list and question count.
    */
    const onUpdateCommentOnly = () => {
        if (!comment) {
            return setCommentError('Comment field is required');
        }
        setCommentError('');

        const obj: any = {
            "companyId": selectedQuestion.company_id,
            "fileId": selectedQuestion.fileId,
            "questionId": selectedQuestionData?._id,
            "answer": selectedQuestionData?.answer,
            "reviewstatus": selectedQuestionData?.reviewstatus,
            "llmstatus": ((selectedQuestionData?.llmReviewRespons == false && selectedQuestionData?.llmstatus) ? true : selectedQuestionData?.reviewstatus ? selectedQuestionData?.llmstatus : false),
            "userId": authData._id,
            "comment": comment,
            "userName": `${authData.firstName} ${authData.lastName}`
        }

        const answerHistory:any[]= selectedQuestionData?.answerHistory || [];
        dispatch(enterprseUpdateQuestion(obj, (activeIndex - 1), answerHistory));
        setComment('');

        if (obj['comment']) {
            setTimeout(() => {
                const qId: string = selectedQuestionData?._id || '';
                getQuestionComment(qId);
            }, 10);
        }
    }

    /**
     * Changes the status of the selected question based on the provided type.
     * @param type - The type of status change to be applied. Can be 'markReview', 'markApprove', or 'markApproveForLLMFalse'.
    */
    type type = "markReview" | "markApprove" | "markApproveForLLMFalse"
    const onChangeStatus = (type: type) => {
        if (type == 'markReview') {
            setSelectedQuestionData((prev: any) => ({ ...prev, reviewstatus: !prev?.reviewstatus }))
        }

        if (type == 'markApprove') {
            if (selectedQuestionData?.reviewstatus) {
                setSelectedQuestionData((prev: any) => ({ ...prev, llmstatus: !prev?.llmstatus }))
            }
        }

        if (type == 'markApproveForLLMFalse') {
            setSelectedQuestionData((prev: any) => ({ ...prev, llmstatus: !prev?.llmstatus }))
        }
    }

    /**
     * Calls the function to update the question and comment whenever
     * the `llmstatus` or `reviewstatus` of the selected question data changes.
    */
    useEffect(() => {
        if (answer) {
            onUpdateQuestionaOnly();
        }
        dispatch(setReviewCount({}))
    }, [selectedQuestionData?.llmstatus, selectedQuestionData?.reviewstatus])


    // Function to filter questions based on activeTab
    const getFilteredQuestions = () => {
        if (!selectedQuestion || !selectedQuestion.questions || selectedQuestion.questions.length == 0) {
            return [];
        }

        // Add original questionNo to each item
        const questionsWithOriginalIndex = selectedQuestion.questions.map((item: any, index: number) => ({
            ...item,
            questionNo: index + 1, // Original question number
        }));

        // Filter the questions based on activeTab
        const filteredQuestions = questionsWithOriginalIndex.filter((item: any) => {
            if (activeTab === 2) {
                return !item.reviewstatus && !item.llmstatus;
            } else if (activeTab === 3) {
                return item.llmstatus;
            }
            return true; // For activeTab 1, show all
        });

        // Add filteredIndex to each filtered item
        const data: any = filteredQuestions.map((item: any, index: number) => ({
            ...item,
            filteredIndex: index + 1, // New index after filtering
        }));
        return data;
    };

    /**
     * Filters the questions based on certain criteria.
    */
    const filteredQuestions = getFilteredQuestions();
    if (filteredQuestions && filteredQuestions[0] && filteredQuestions[0]?.questionNo && activeIndex == 0) {
        setActiveIndex(filteredQuestions[0]?.questionNo);
    }

    /**
     * Simulates a click on the first question in the list when the active tab changes.
    */
    useEffect(() => {
        document.getElementById('list-no-0')?.click();
        setTimeout(() => {
            document.getElementById('list-no-0')?.click();
        }, 400);
    }, [activeTab])

    /**
       * filter and remove selected answer
      */

    useEffect(() => {
        if (filteredQuestions && filteredQuestions.length) {
            setTimeout(() => {
                document.getElementById('list-no-0')?.click();
            }, 500);
        }
    }, [filteredQuestions.length]);

    /**
     * Downloads the selected question's file.
    */
    // const downloadQuestion = async () => {
    //     await EnterpriseService.downloadQuestion(selectedQuestion.fileId);
    // }

    const onFileDownload = async () => {
        if (selectedQuestionData && selectedQuestionData?.bucketName) {
            const fileName: any = selectedQuestionData?.documentName || '';
            const bucketName: string = selectedQuestionData.bucketName;

            // Explicitly type selectedQuestionData.fileAndFolder as string | undefined
            let fileAndFolder: string[] = [];
            const fileAndFolderValue: string | any = selectedQuestionData?.fileAndFolder;
            if (fileAndFolderValue && typeof fileAndFolderValue === 'string') {
                fileAndFolder = fileAndFolderValue.split('/');
            }

            let folderName: string = fileAndFolder[0];
            const fileId: string = fileAndFolder[1];
            const data: any = companyList.filter((item: any) => item._id == selectedQuestion.company_id)
            if (data && data[0]) {
                folderName = data[0]?.complianceFolder;
            }

            S3DownloadForEnterprise({ fileKey: fileId, bucketName, folderName, newFileName: fileName }).then((res: any) => {
                console.log(res, 'res');
                // createDownloadLogs([selectedQuestion.fileName], 'enterprise-apps', 'editor', true);
            }).catch((err: any) => {
                console.log(err, 'err file');
            })
        }
    };

    //convert time zone UTC to PST 
   // Function to convert UTC date string to Los Angeles time (PST/PDT)
const convertUTCToPST = (utcDateStr:string) => {
    // Create a Date object from the UTC string
    const utcDate = new Date(utcDateStr);
    
    // Convert UTC to Los Angeles time (PST/PDT)
    const pstDate = utcDate.toLocaleString('en-US', { 
        timeZone: 'America/Los_Angeles',
        hour12: true, // Use 12-hour time format with AM/PM
    });

    return pstDate;
}


    const totalQuestion = Number(`${(selectedQuestion && selectedQuestion.questions ? (selectedQuestion.questions.length) : 0)}`);
    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'Vendors'} />
                <div className="router-container">

                    {/* new code start */}
                    <div className="back-button pointer" onClick={() => navigate(-1)}>
                        <img className='back-arrow pointer' src={process.env.PUBLIC_URL + '/assets/imgs/back-arrow.png'} alt="back-icon" style={{ width: '16px', marginRight: '8px' }} />
                        <span>{params.questionnareId}</span>
                    </div>

                    <div className="review-divider" />
                    <div className="account-container">
                        <div className="account-name-container">
                            <p className="account-heading" style={{fontWeight:'600', fontSize:14}}>ACCOUNT</p>
                            <div className="account-name">
                                <h3 style={{ marginRight: '8px', fontSize:14, fontWeight:'600' }}>{companyName}</h3>
                            </div>
                        </div>
                        <div style={{ width: '1px', height: '54px', background: "#D8D8D8", marginLeft:22 }} />
                        <div className="account-progress-container">
                            <h3 style={{ fontSize: '14px',fontWeight:'600' }}>PROGRESS</h3>
                            {/* progres */}
                            <div style={{ marginTop: '8px', marginBottom: '6px', height: '4px', width: '140px', background: '#D8D8D8', borderRadius: '8px', overflow: 'hidden' }}>
                                <div style={{ width: `${((countReviewItem?.countOfApproved || 0) * 100) / totalQuestion}%`, background: 'green', height: 4 }} />
                            </div>
                            <p style={{ fontSize: '12px' }}>
                                {(((countReviewItem?.countOfApproved || 0) * 100) / totalQuestion).toFixed(0)}% | {(countReviewItem?.countOfApproved || 0)}/{totalQuestion} questions approved
                            </p>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="options-navigator sticky-top" style={{ top: '60px' }}>
                            <button className={activeTab === 1 ? 'tabActive' : 'options-item'} onClick={() => handleTabsClick(1)}>
                                <h3> VIEW ALL </h3>
                            </button>
                            <div className={activeTab === 2 ? 'tabActive' : 'options-item'} onClick={() => handleTabsClick(2)}>
                                <h3>NEEDS REVIEW<span className="options-tag">{countReviewItem.countOfReview}</span></h3>
                            </div>
                            <div className={activeTab === 3 ? 'tabActive' : 'options-item'} onClick={() => handleTabsClick(3)}>
                                <h3>APPROVED<span className="options-tag">{countReviewItem?.countOfApproved || '0'}</span></h3>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-5 questions-lists">
                                        <div className="questions-container mb-2">
                                            {/* handleSelect(index, questions[key]) */}
                                            {filteredQuestions.map((item: any, index: number) => (
                                                <div
                                                    key={`qnair-${item?.questionNo}`}
                                                    id={`list-no-${index}`}
                                                    className={activeIndex === item?.questionNo ? "question-item-active" : "question-item"}
                                                    onClick={() => handleSelect(item?.questionNo, item)}
                                                >
                                                    <div style={{ width: 16, height: 16, marginRight: 10 }}>
                                                        <CheckMark
                                                            width={18}
                                                            height={18}
                                                            color={((item?.llmReviewRespons == false && item?.llmstatus) ? 'success' : (item?.reviewstatus && item?.llmstatus
                                                                ? 'success'
                                                                : (item?.reviewstatus || item?.llmstatus)
                                                                    ? 'warn'
                                                                    : 'default'))}
                                                        />
                                                    </div>
                                                    <div className={activeIndex === item?.questionNo ? "white-text" : "normal"}>Q{item?.questionNo}</div>
                                                    <div className={activeIndex === item?.questionNo ? "white-text" : "normal"} style={{ marginLeft: '4px', marginRight: '4px' }}>
                                                        {' '}|{' '}
                                                    </div>
                                                    <div className={activeIndex === item?.questionNo ? "question-text-white" : "question-text"}>
                                                        {' '}{item?.question}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        {filteredQuestions.length ?
                                            <>
                                                <div className="answers-container">
                                                    <div className='mb-2'>Answer <img src={process.env.PUBLIC_URL + '/assets/imgs/ai-generated.png'} alt="ai-generated" style={{ width: '24px' }} /></div>
                                                    <textarea rows={4}
                                                        onChange={(e: any) => setAnswer(e.target.value)}
                                                        className='answers-box'
                                                        id="questionnaire-answer"
                                                        name="questionnaire-answer"
style={{ fontFamily: 'Arial', width: '100%', border: 'none' }}
                                                        value={answer}
                                                    />

                                                    <small className='text-danger'>{answerError}</small>
                                                    <p style={{ fontStyle: 'italic', color: '#837A7A', fontSize: '14px', marginBottom: '15px' }}>Last modified {convertUTCToPST(answerLastModified)}</p>
                                                    

                                                    <div className="d-flex justify-content-between" style={{marginTop:12}}>
                                                        <button onClick={onUpdateQuestionaOnly} className="save-comment">Save</button>
                                                    </div>

                                                    
                                                    <div className={'mt-4'}>Comments</div>
                                                    <textarea
                                                        onChange={(e: any) => setComment(e.target.value)} rows={3}
style={{ fontFamily: 'Arial', marginBottom: (commentError ? 0:12), marginTop: '8px', width: '100%', border: 'none' }}
                                                        value={comment}
                                                    />
                                                    <small className='text-danger pb-2'>{commentError}</small>
                                                    <div className="d-flex justify-content-between">
                                                        <button onClick={onUpdateCommentOnly} className="save-comment">Save</button>
                                                    </div>

                                                    <Tabs
                                                    defaultActiveKey="history"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3 mt-4 my-tabs-enterprise"
                                                >
                                                    <Tab eventKey="history" title="History">
                                                        <div className="commented-list mt-4" style={{maxHeight:300, overflowY:'auto'}}>
                                                            {selectedQuestionData?.answerHistory && selectedQuestionData?.answerHistory.length ? <>
                                                                {selectedQuestionData?.answerHistory.map((item: any, index: number) => (
                                                                    <div key={`comment-${index}`} className="commented-box">
                                                                        <p>
                                                                            <img src={process.env.PUBLIC_URL + '/assets/imgs/ai-generated-orange.png'} alt="" />
                                                                            {item?.answer}
                                                                        </p>
                                                                        <div>
                                                                        {item?.name ? <button className='internal'>{item?.name}</button> : null} <i className='last-modif'>Latest modified {convertUTCToPST(item.createdAt)}</i>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>:<div className='text-center mt-3'>No History Found</div>}
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="comments" title="Comments">
                                                        <div className="commented-list mt-4" style={{maxHeight:300, overflowY:'auto'}}>
                                                        {commentList && commentList.length ? <>
                                                            {commentList.map((item: any, index: number) => (
                                                                <div key={`comment-${index}`} className="commented-box">
                                                                    <p>
                                                                        <img src={process.env.PUBLIC_URL + '/assets/imgs/ai-generated.png'} alt="" />
                                                                        {item.comment}
                                                                    </p>
                                                                    <div>
                                                                        {item?.user?.firstName ? <button className='internal'>{item?.user?.firstName}</button> : null} <i className='last-modif'>Latest modified {convertUTCToPST(item.updatedAt)}</i>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>:<div className='text-center mt-3'>No Comments Found</div>}
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="context" title="Context">
                                                        <div className="commented-list mt-4" style={{maxHeight:300, overflowY:'auto'}}>
                                                        {selectedQuestionData && selectedQuestionData?.retrievedNarrative ? <>
                                                            
                                                                <div key={`comment-`} className="commented-box">
                                                                    <p>
                                                                        <img src={process.env.PUBLIC_URL + '/assets/imgs/ai-generated.png'} alt="" />
                                                                        {selectedQuestionData?.retrievedNarrative}
                                                                    </p>
                                                                    
                                                                </div>
                                                        
                                                        </>:<div className='text-center mt-3'>No Context Found</div>}
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                                </div>


                                                
                                            </> : <div>
                                                <div style={{ marginTop: 100 }}>No {activeTab==2 ? 'Reviewed' : 'Approved'} List found</div>
                                            </div>}



                                    </div>

                                    <div className="col-lg-2 mt-5 position-relative">
                                        {filteredQuestions.length ? <>
                                            <section style={{ fontSize: '12px' }}>
                                                {selectedQuestionData?.llmReviewRespons == true ? (
                                                    <>
                                                        <div onClick={() => onChangeStatus('markReview')} className='checkRadio pointer' >
                                                            <CheckMark width={18} height={18} color={selectedQuestionData?.reviewstatus ? 'success' : 'default'} />
                                                            <span className='ms-2'>Mark as Reviewed</span>
                                                        </div>

                                                        {/* <div className={`checkRadio ${selectedQuestionData?.reviewstatus ? 'pointer' : 'no-drop'}`} onClick={() => onChangeStatus('markApprove')} style={{ opacity: (selectedQuestionData?.reviewstatus ? 1 : 0.3), marginTop: 15 }}>
                                                            <CheckMark width={18} height={18} color={selectedQuestionData?.llmstatus && selectedQuestionData?.reviewstatus ? 'success' : 'default'} />
                                                            <span className='ms-2'>Mark as approved</span>
                                                        </div> */}
                                                    </>
                                                ) : <>
                                                    <div className={`checkRadio pointer`} onClick={() => onChangeStatus('markApproveForLLMFalse')} >
                                                        <CheckMark width={18} height={18} color={selectedQuestionData?.llmstatus ? 'success' : 'default'} />
                                                        <span className='ms-2'>Mark as approved</span>
                                                    </div>

                                                </>}

                                                {selectedQuestionData?.documentName ? (
                                                    <>
                                                        <div className="ms-2 mt-4 pointer fw-bold">#Source File</div>
                                                        <div onClick={() => onFileDownload()} className='ms-2 mt-1 pointer fw-bold text-break text-decoration-underline' style={{color:'#d1b015eb'}}>{selectedQuestionData?.documentName}</div>
                                                    </>
                                                ) : null}
                                            </section>
                                        </> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Review;
