interface ToastManagerCallbacks {
    openDialog?: (params: ToastManagerParams) => void;
    closeDialog?: () => void;
}

interface ToastManagerParams {
    title: string;
    type: 'success' | 'warn' | 'danger';
    message: string;
    onCancel?: () => void;
}

class ToastManager {
    private callbacks: ToastManagerCallbacks = {};

    setCallbacks(callbacks: ToastManagerCallbacks) {
        this.callbacks = callbacks;
    }

    show(params: ToastManagerParams) {
        if (this.callbacks.openDialog) {
            this.callbacks.openDialog(params);
        }
    }

    close() {
        if (this.callbacks.closeDialog) {
            this.callbacks.closeDialog();
        }
    }
}

export const toastManager = new ToastManager();