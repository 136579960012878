// Navbar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
const Navbar: React.FC = () => {
  return (
    <div className="navbar">
      <div className="logo">
        {/* Your Logo here */}
        <img src={process.env.PUBLIC_URL+'assets/imgs/nav-logo.png'} alt="nav-logo" style={{ width: '160px', paddingTop: '20px' }} />
      </div>
      <nav>
        <NavLink to="/dashboard" className={'active'}>Dashboard</NavLink>
        <NavLink to="/trust-center" className="active">Trust Center</NavLink>
        <NavLink to="/enterprise-apps" className="active">Enterprise Apps</NavLink>
        <NavLink to="/main" className="active">Conversations</NavLink>
        <NavLink to="/ai-questionnaire" className="active">AI Questionnaire Auto Complete</NavLink>
        <NavLink to="/security-portal" className="active">Security Portal</NavLink>
        <NavLink to="/settings" className="active">Settings</NavLink>
        <NavLink to="/help-center" className="active">Help Center</NavLink>
        <NavLink to="/api-guide" className="active">API Guide</NavLink>
      </nav>
      <div className="footer">
        <NavLink to="/terms" className="active">Terms of Service</NavLink>
        <NavLink to="/privacy" className="active">Privacy Policy</NavLink>
        <div>© 2024 Targhee Security</div>
      </div>
    </div>
  );
};

export default Navbar;
