import React, { useEffect, useState } from 'react';
import WithSidemenuHeader from '../../Components/WithSidemenuHeader';
import SideMenu from '../../Components/SideMenu';
import './EnterpriseApps.css';
import EnterpriseService from '../../ApiServices/Enterprise.Service';
import { getCompanyList, getComplianceData, getQuestionnairesData, setSelectedQuestion, deleteCompanyRequest, getClientDetails } from "../../Redux/Actions/SagaAction";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import { Card, Col, Row } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmationManager } from '../../Components/ConfirmationDialog/ConfirmationManager';
import { useNavigate } from "react-router-dom";
import AddVendor from './AddVendor';
import Table from 'react-bootstrap/Table';

// BUCKET_NAME_VALIDATION
function validateS3BucketName(companyName: string) {
  // Check for leading or trailing whitespace
  if (companyName !== companyName.trim()) {
    return "Company name cannot have leading or trailing whitespace.";
  }

  if (companyName.length < 3 || companyName.length > 63) {
    return "Company name must be between 3 and 63 characters.";
  }

  const allowedCharacters = /^[A-Za-z0-9 ]+$/;
  if (!allowedCharacters.test(companyName)) {
    return "Company name can only contain alphabate, numbers, and spaces.";
  }
  return null;
}


const EnterpriseAppsDashboard: React.FC = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.AppReducer);
  const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);
  const [addVendorModal, setAddVendorModal] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (EnterpriseService.getCompanyInfo && EnterpriseService?.getCompanyInfo?._id) {
      onChangeCompany(EnterpriseService?.getCompanyInfo?._id);

    } else if (data.companyList && data.companyList?.length) {
      const lastIndex = data.companyList?.length - 1;
      onChangeCompany(data?.companyList[lastIndex]?._id);
    }

  }, [data.companyList])


  useEffect(() => {
    /**@REDUX_CALL */
    dispatch(getCompanyList());
    dispatch(getClientDetails());
    // const gres = await getClientDetails();
  }, [dispatch]);


  /**
   * Handles the change in company selection, updates the selected company, 
   * and dispatches actions to fetch compliance and questionnaires data.
   * @param e - The change event from the company selection input.
  */
  const onChangeCompany = async (val: any) => {
    EnterpriseService.selectedCompanyId = val;
    for (let i = 0; i < data.companyList.length; i++) {
      if (data.companyList[i]?._id == val) {
        EnterpriseService.setCompnayInfo = data.companyList[i];
        break;
      }
    }

    if (val != 'new' || val != '') {
      try {
        setAddVendorModal(false);
      } catch (error) {
        setAddVendorModal(false)
      }
    }
  };

  /**
   * Handles the open in company selection, updates the selected company, 
   * and dispatches actions to fetch compliance and questionnaires data.
   * @param e - The change event from the company selection input.
  */
  const openVendorDetails = async (val: any) => {
    EnterpriseService.selectedCompanyId = val?._id;
    EnterpriseService.setCompnayInfo = val;
    if (val != 'new' || val != '') {
      try {
        dispatch(getComplianceData(val?._id));
        dispatch(getQuestionnairesData(val?._id));
        setAddVendorModal(false);
        navigate("/enterprise-vendor-details");
      } catch (error) {
        console.log(error, 'error');
        setAddVendorModal(false)
      }
    }

 
    const { item, itemType } = data;
    if (item && item['files'] && item['files'].length) {
      dispatch(setSelectedQuestion({
        questions: item['files'][0]['questions'],
        fileName: item['files'][0]['fileName'],
        fileId: item['files'][0]['_id'],
        company_id: item['company_id'],
        _id: item['_id'],
        createdAt: item['createdAt'],
      }));
    }

    if (itemType == 'documents') {
      return;
    }
  };


  /**@DELETE_PEOPLE */
  const deleteCompany = (item: any) => {
    confirmationManager.requestConfirmation({
      heading:'Confirm Deletion',
      message: 'Are you sure you want to delete this item?',
      onConfirm: async () => {
        const obj: any = {
          companyId: item._id,
          compliance_bucket: clientDetails.complianceBucket,
          questionnaires_bucket: clientDetails.questionnairesBucket,
        }
        dispatch(deleteCompanyRequest(obj));
      },
      onCancel: () => {
        console.log('Delete canceled');
      },
    });
  };

  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title={'Vendor List'} />
        <div className="router-container">
          <div className="enterprise-container mt-3" style={{height:'calc(100vh - 120px)', minHeight:690}}>
            <Row>
              <Col xs={12} sm={6} lg={6}></Col>
              <Col xs={12} sm={6} lg={6} className='text-end'>
                <Button onClick={() => setAddVendorModal(true)} variant="secondary">Create New Vendor</Button>
              </Col>
            </Row>

            <div className='mb-3'></div>
            <Table striped bordered responsive >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vendor Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.companyList.length==0 ? (<tr>
                      <td colSpan={3} className={'align-middle text-center'}><b>No Data found</b></td>
                    </tr>) :null}
                {data.companyList.map((item: any, index: number) => {
                  return (
                    <tr key={`enterprise-new-vendor-list${index}`}>
                      <td className={'align-middle'}>{(index+1)}.</td>
                      <td className={'align-middle'}>{item.companyName}</td>
                      <td>
                        <Button variant={'link'} onClick={() => deleteCompany(item)} className={'pointer'}><FontAwesomeIcon color='#d90429' icon={faTrash} /></Button>
                        <Button variant={'link'} onClick={() => openVendorDetails(item)} className={'pointer'}><FontAwesomeIcon color='#258af0' icon={faEye} /></Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </section>

      <AddVendor
        show={addVendorModal}
        onHide={() => setAddVendorModal(false)}
        onChangeCompany={onChangeCompany}
      />
    </main>
  )
}
export default EnterpriseAppsDashboard;