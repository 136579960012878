
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Download.css'
import EnterpriseService from './../../../ApiServices/Enterprise.Service';
import { S3DownloadForEnterprise } from './../../../ApiServices/S3Bucket';
// import { createDownloadLogs } from './../../../ApiServices/TrustCenter';
interface Props {
    loadingTime: number;
    listType?: string;
    showDownloadPage?: boolean;
}
function Download({ loadingTime, listType, showDownloadPage }: Props) {
    const [percentage, setPercentage] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedQuestion = useSelector((state: any) => state.AppReducer.selectedQuestion);
    const companyList = useSelector((state: any) => state.AppReducer.companyList);
    const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);

    useEffect(() => {
        let interval;
        if (loadingTime <= 1000) {
            interval = setInterval(() => {
                setPercentage((prev) => Math.min(prev + 20, 100));
            }, 200);
        } else {
            const increment = 100 / (loadingTime * 1000 / 10);
            interval = setInterval(() => {
                setPercentage((prev) => Math.min(prev + increment, 100));
            }, 10);
        }
        if (percentage > 99) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [loadingTime]);

    /**
     * Initiates the download of the file associated with the selected question.
    */
    const downloadQuestion = async () => {
        if(listType == 'only-doument-download'){
            // https://happyflaskstage.com/download-docs
            onFileDownload()
        //    fileName: "Asana ISO 27001_2022 Certificate (2024-2027).pdf 
        }else{
            await EnterpriseService.downloadQuestion(selectedQuestion.fileId);
        }
    }

    const onFileDownload = async () => {
        let folderName:string="";
        const bucketName:string=clientDetails.complianceBucket;
        const data:any=companyList.filter((item:any)=>item._id==selectedQuestion.company_id)
        if(data && data[0]){
            folderName=data[0]?.complianceFolder;
        }

        S3DownloadForEnterprise({ fileKey: selectedQuestion?.fileId, bucketName, folderName, newFileName: selectedQuestion.fileName }).then((res: any) => {
            console.log(res, 'res');
            // createDownloadLogs([selectedQuestion.fileName], 'enterprise-apps', 'editor', true);
        }).catch((err: any) => {
            console.log(err, 'err file');
        })
    };

    const radius = 40; // Radius of the circle
    const strokeWidth = 8; // Stroke width of the circle
    const circumference = 2 * Math.PI * radius; // Calculate circumference

    // Calculate the stroke-dashoffset based on the percentage
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    const totalQuestion = `${(selectedQuestion && selectedQuestion.questions ? (selectedQuestion.questions.length) : 0)}`;
    return (
        <div className="right-wrapper">
            {/* circle loader start */}
            <svg className="circle-loader" width={radius * 2} height={radius * 2}>
                <circle
                    className="circle-loader-background"
                    cx={radius}
                    cy={radius}
                    r={radius - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                />
                <circle
                    className="circle-loader-progress"
                    cx={radius}
                    cy={radius}
                    r={radius - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                />
                <text className="circle-loader-text text-white" x={radius} y={radius}>{percentage}%</text>
            </svg>
            {/* circle loader end */}

            <div>
                <div className='mb-2'>Targhee Secure AI</div>
                <div>Processing Complete</div>
            </div>

            {listType != 'only-doument-download' ? (
                <>
                <h2 className='my-3'>
                    <span>
                        <span className='text-primary'> {totalQuestion}</span>
                        /{totalQuestion} Questions Answered!
                    </span>
                </h2>

                <div className="mb-3">
                    <p>We were able to find answers for {totalQuestion}/{totalQuestion} questions.</p>
                    <p>You can review and edit the answers in the next step.</p>
                </div>
                <button className="suggestions-button">
                    <img src={process.env.PUBLIC_URL + 'assets/imgs/bulb.png'} alt="bulb" style={{ width: '20px', marginRight: '8px' }} />
                    <span>{totalQuestion} suggestions</span>
                </button>
                <div style={{ height: '1px', width: '180px', background: 'black', marginTop: '24px', marginBottom: '24px' }} />
                <div className="incomplete-container">
                    <img src={process.env.PUBLIC_URL + 'assets/imgs/circle-half.png'} alt="half-circle" style={{ width: '28px', marginRight: '4px' }} />
                    <span>0 incomplete</span>
                </div>
                </>
            ) : null}
            
            <div className="buttons-container">
                {listType != 'only-doument-download' ? (
                    <button className="review-button" onClick={() => {
                        navigate(`/enterprise-review/${selectedQuestion?.fileName ? selectedQuestion?.fileName : ''}`)
                    }}>
                        Review Answers
                    </button>
                ) : null}

                <button className="download-button" onClick={() => downloadQuestion()}>
                    Download
                </button>
            </div>
        </div>
    )
}

export default Download